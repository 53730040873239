<template>
  <button :disabled="isEmpty" :class="state" @click.prevent="download">
    <slot />
  </button>
</template>

<script>
import { sleep, api, apiUrl } from '@/utils'
import Frame from '@/views/Frame'

export default {
  name: 'FileButton',
  props: {
    document: {
      type: Object,
      required: true,
    },
    filetype: {
      type: String,
      required: true,
    },
  },
  computed: {
    state () {
      if (this.isEmpty) {
        return 'empty'
      }
      if (!this.state_file.downloaded_at) {
        return 'syncing'
      }
      return 'active'
    },
    isEmpty () {
      return !this.state_file
    },
    state_filetype () {
      if (this.filetype === 'import_declaration_1') {
        return 'all'
      }
      return this.filetype
    },
    state_file () {
      for (const file of this.document.files) {
        if (file.filetype === this.state_filetype) {
          return file
        }
      }
      return null
    },
  },
  methods: {
    async download () {
      const url = `/api/yura/import/document/${this.document.invoice_no}/download/${this.filetype}`
      window.open(url, '_blank')
    },
  },
}
</script>

<style scoped lang="scss">
button {
  // green background button
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 60px;
  height: 50px;
  font-size: 14px;
  &.active {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
  }
  &.syncing {
    // orange
    background-color: #FF9800;
    color: white;
    cursor: wait;
  }
}
</style>
