<template>
  <div class="form">
    <div class="row">
      <label>인보이스 날짜</label>
      <div class="fields">
        <div class="row">
          <input type="date" :value="value.invoice_date_from" @change="update({invoice_date_from: $event.target.value})" />
          -
          <input type="date" :value="value.invoice_date_to" @change="update({invoice_date_to: $event.target.value})" />

        </div>
        <div class="row">
          <button
            @click="update({invoice_date_from: null, invoice_date_to: null})"
            :class="{active: value.invoice_date_from === undefined && value.invoice_date_to === undefined}" >
            전체
          </button>
          <button
            @click="update({invoice_date_from: dayBeforeYesterdayDate, invoice_date_to: dayBeforeYesterdayDate})"
            :class="{active: value.invoice_date_from === dayBeforeYesterdayDate && value.invoice_date_to === dayBeforeYesterdayDate}" >
            그제 ({{dayBeforeYesterdayDate}})
          </button>
          <button
            @click="update({invoice_date_from: yesterdayDate, invoice_date_to: yesterdayDate})"
            :class="{active: value.invoice_date_from === yesterdayDate && value.invoice_date_to === yesterdayDate}" >
            어제 ({{yesterdayDate}})
          </button>
          <button
            @click="update({invoice_date_from: todayDate, invoice_date_to: todayDate})"
            :class="{active: value.invoice_date_from === todayDate && value.invoice_date_to === todayDate}" >
            오늘 ({{todayDate}})
          </button>
          <button
            @click="update({invoice_date_from: startDayOfThisWeek, invoice_date_to: todayDate})"
            :class="{active: value.invoice_date_from === startDayOfThisWeek && value.invoice_date_to === todayDate}" >
            이번주 ({{startDayOfThisWeek}} - {{todayDate}})
          </button>
          <button
            @click="update({invoice_date_from: sevenDaysAgo, invoice_date_to: todayDate})"
            :class="{active: value.invoice_date_from === sevenDaysAgo && value.invoice_date_to === todayDate}" >
            일주일 ({{sevenDaysAgo}} - {{todayDate}})
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <label>삭제된 문서</label>
      <div class="fields">
        <button
          @click="update({deleted: null})"
          :class="{active: value.deleted === undefined}"> 포함 </button>
        <button
          @click="update({deleted: 'false'})"
          :class="{active: value.deleted === 'false'}"> 미포함 </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'


export default {
  name: 'FilterForm',
  props: {
    value: Object,
  },
  components: {
  },
  created () {
  },
  methods: {
    update (updateFilter) {
      let newValue = {...this.value, ...updateFilter}
      // remove null
      Object.keys(newValue).forEach(key => newValue[key] === null && delete newValue[key])
      this.$emit('update', newValue)
    },
  },
  computed: {
    todayDate () {
      return moment().format('YYYY-MM-DD')
    },
    yesterdayDate () {
      return moment(this.todayDate).subtract(1, 'days').format('YYYY-MM-DD')
    },
    dayBeforeYesterdayDate () {
      return moment(this.todayDate).subtract(2, 'days').format('YYYY-MM-DD')
    },
    startDayOfThisWeek () {
      return moment(this.todayDate).startOf('week').add(1, 'days').format('YYYY-MM-DD')
    },
    sevenDaysAgo () {
      return moment(this.todayDate).subtract(7, 'days').format('YYYY-MM-DD')
    },
  },
}
</script>

<style lang="scss">
.form {
  display: flex;
  flex-direction: column;

  padding: 20px;

  border-radius: 5px;
  background-color: #EEE;
  .row {
    display: flex;
    align-items: center;

    margin-bottom: 10px;
    label {
      font-size: 12px;
      font-weight: bold;
      width: 100px;
      margin-right: 10px;
    }
    .fields {
      gap: 10px;
      .row {
      }
      input {
      }
      button {
        &.active {
          background-color: #333;
          color: white;
        }
      }
    }
  }
}
</style>
