<template>
  <Container>
    <div class="home">
      <h1>수출물품 잔량</h1>
      <label>
        자료 업로드 : 
        <input
        type="file"
        ref="file"
        accept=".xlsx, .xls" />
      </label>
      <br>
      <br>
      <div class="buttons" v-if="!uploading && !token">
        <button @click="upload">잔량 검사</button>
      </div>
      <div class="error-message"> 
        {{ errorMessage }}
      </div>
      <div class="count" v-if="doneCount && totalCount">
        {{ doneCount }} / {{ totalCount }}
      </div>
      <div class="message" v-for="(message, index) in messages" :key="index"> 
        {{ message }}
      </div>
      <a v-if="resultFile" :href="'/api/remaining/result?token=' + token">결과파일 다운로드</a>
      <form action="/api/receipt/export" method="post" enctype="multipart/form-data">
        <h1>수출 청구서 변환</h1>
        <label>
          자료 업로드 : 
          <input
          type="file"
          name="export_file"
          accept=".xlsx, .xls" />
        </label>
        <div class="buttons">
          <input type="submit"/>
        </div>
      </form>
      <form action="/api/receipt/import" method="post" enctype="multipart/form-data">
        <h1>수입 청구서 변환</h1>
        <label>
          자료 업로드 : 
          <input
          type="file"
          name="import_file"
          accept=".xlsx, .xls" />
        </label>
        <div class="buttons">
          <input type="submit"/>
        </div>
      </form>
    </div>
  </Container>
</template>

<script>
import axios from 'axios'

import { sleep, api, apiUrl } from '@/utils'
import Frame from '@/views/Frame'

export default {
  name: 'HomeView',
  components: {
    Container: Frame,
  },
  methods: {
    async upload () {
      this.uploading = true
      this.token = null
      const files = this.$refs.file.files
      if (files.length ===  0) {
        this.errorMessage = '파일을 선택해주세요.'
        this.uploading = false
        return
      } else if (files.length > 1) {
        this.errorMessage = '하나의 파일만 선택할 수 있습니다.'
        this.uploading = false
        return
      }

      const file = files[0]
      let formData = new FormData()
      formData.append('file', file);
      const requestData = {
        method: 'post',
        url: apiUrl('/remaining'),
        data: formData,
        withCredentials: true,
        validateStatus () {
          return true
        }
      }
      const res = await axios(requestData)
      if (res.status !== 200) {
        this.errorMessage = '업로드 실패'
        this.uploading = false
        return
      }
      this.errorMessage = '업로드 성공!'
      this.token = res.data.token
      this.totalCount = -1
      this.uploading = false
      this.checkState()
    },
    async checkState () {
      if (!this.token) {
        return
      }
      this.state = 2
      this.resultFile = false
      while (!this.resultFile) {
        await sleep(1000)
        const res = await api('GET', '/remaining/state', {token: this.token})
        if (res.status !== 200) {
          this.errorMessage = '잔량조회 실패(' + res.status + ')'
          return
        }
        if (res.data.error) {
          this.errorMessage = '잔량조회 실패(' + res.data.error + ')'
          return
        }
        this.totalCount = res.data.total
        this.doneCount = res.data.done
        this.messages = res.data.messages
        this.resultFile = res.data.result_file
      }
      this.errorMessage = '잔량조회 완료!'
    }
  },
  data () {
    return {
      uploading: false,
      token: null,
      totalCount: 0,
      doneCount: 0,
      errorMessage: null,
      messages: [],
      resultFile: false
    }
  }
}
</script>
